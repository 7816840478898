<template>
    <div class="main-container">
        <transition name="fade-transform" mode="out-in">
            <keep-alive :include="cachedViews" :max="20">
                <router-view :key="key"></router-view>
            </keep-alive>
        </transition>
    </div>
</template>

<script>
    import {remove} from '@/util/objects'
    import {mapState} from 'vuex'

    export default {
        name: 'MainContainer',
        computed: {
            ...mapState({cachedViews: state => state.viewTag.cachedViews}),
            key() {
                return this.$route.path;
            },
        }
    }
</script>
